import { useEffect } from 'react';
import './App.css';
import { Home } from './pages/Home';

function App() {
  useEffect(() => {
    const preventContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      console.log("Right-click prevented globally");
    };

    document.addEventListener('contextmenu', preventContextMenu);

    return () => {
      document.removeEventListener('contextmenu', preventContextMenu);
    };
  }, []);

  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
